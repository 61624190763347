::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.00)
}

::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .3);
    outline: 1px solid slategrey;
}