.link, .link:visited {
    height: 100%;
    color: black;
    text-decoration: none;
}

.link h2 {
    text-decoration: none;
}

.ref-link, .ref-link:visited {
    color: #a00000;
}

.ref-link:hover {
    color: #00b8e6;
}