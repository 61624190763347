body {
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;
    position: absolute;

    /*background-image: url("../Resources/main-bg-white.png");*/
    /*filter: invert(100%);*/
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    background-position: bottom right;
    background-repeat: no-repeat;

    background-color: white;
    color: black;
    font-family: "Arial", "Helvetica Neue", sans-serif;
}

#root {
    width: 100%;
    min-height: 100%;
}

#page_wrapper {
}

@media screen and (max-width: 800px) {
    #page_wrapper {
        margin: 0;
    }
}