#splash_wrapper {
    width: 100%;
    height: 100%;

    margin: 0;
    padding: 0;
    position: absolute;

    background-color: #00b8e6;
    color: black;
    font-family: "Arial", "Helvetica Neue", sans-serif;

    /*background-image: url("../Resources/snake_sex.jpg");*/
    -webkit-background-size: cover;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;

    display: flex;
    justify-content: center;
    align-items: center;
}

/* Title */
#title_wrapper {
    margin: 0 auto;
    text-align: center;
    color: white;
    text-shadow:
            -2px -2px 0 #000,
            2px -2px 0 #000,
            -2px 2px 0 #000,
            2px 2px 0 #000;
}

#page_title {
    font-size: 15vmin;
    margin: 10px 0;
}

#page_subtitle {
    font-size: 5vmin;
    margin: 10px 0;
    display: inline-block;
    padding: 0 10px;
}

.hover_title:hover {
    cursor: pointer;
    background-color: rgb(6, 9, 148);
}
